/* unplugin-vue-components disabled */var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{ref:"table",attrs:{"table-id":"connections","columns":_vm.columns,"loader":_vm.tableLoader,"item-id":"id","selectable":false},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"status-wrap"},[(
                    item.initial_ready === 0 &&
                    item.status !== 10 &&
                    item.status !== 20
                )?_c('div',{staticClass:"status-wrap__item"},[_c('span',{staticClass:"dot green"}),_c('spinner',{attrs:{"dark":true}}),_c('span',{staticClass:"status-wrap__item__text"},[_vm._v(" Первичный сбор данных ")])],1):_vm._e(),(item.status === 10)?_c('div',{staticClass:"status-wrap__item"},[_c('spinner',{attrs:{"dark":true}}),_c('span',{staticClass:"status-wrap__item__text"},[_vm._v("Проверка токена")])],1):_vm._e(),(item.status === 20)?_c('div',{staticClass:"status-wrap__item"},[_c('span',{staticClass:"dot red"}),_c('span',{staticClass:"status-wrap__item__text red"},[_vm._v("Ошибка")])]):_vm._e(),(
                    item.status === 30 &&
                    item.state !== 20 &&
                    item.initial_ready === 1
                )?_c('div',{staticClass:"status-wrap__item"},[_c('span',{staticClass:"dot green"}),_c('span',{staticClass:"status-wrap__item__text green"},[_vm._v("Подключен")])]):_vm._e(),(
                    item.status === 30 &&
                    item.state === 20 &&
                    item.initial_ready === 1
                )?_c('div',{staticClass:"status-wrap__item"},[_c('span',{staticClass:"dot gray"}),_c('span',{staticClass:"status-wrap__item__text green"},[_vm._v(" Доступ ограничен ")])]):_vm._e(),(item.status === 60 && item.initial_ready === 1)?_c('div',{staticClass:"status-wrap__item"},[_c('span',{staticClass:"dot gray"}),_c('span',{staticClass:"status-wrap__item__text green"},[_vm._v(" Приостановлен ")])]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }